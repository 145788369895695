import { connect } from 'react-redux';
import RincianPembayaran from '../../../../views/Finance/Spp/RincianPembayaran/RincianPembayaran.view';
import { getParamOptionsAcademics, getYearsOfEducationsOptions } from '../../../../states/thunks/options.thunk';
import { historyPaymentsRutin, historyPaymentsNonRutin, listPaymentGateway, saveTemporary, detailPayments} from '../../../../states/thunks/academics.thunk';

function mapStateToProps(state) {
  return {
    classes: state.options.classes,
    levels: state.options.levels,
    user: state.user || {},
  }
}
function mapDispatchToProps(dispatch) {
  return {
    getParamOptionsAcademics: (payload, type) => dispatch(getParamOptionsAcademics(payload, type)),
    getYearsOfEducationsOptions: (payload) => dispatch(getYearsOfEducationsOptions(payload)),
    handleHistoryPaymentsRutin: (payload, goback) => dispatch(historyPaymentsRutin(payload, goback)),
    handleHistoryPaymentsNonRutin: (payload, goback) => dispatch(historyPaymentsNonRutin(payload, goback)),
    handleListPaymentGateway: (payload, goback) => dispatch(listPaymentGateway(payload, goback)),
    handleSaveTemporary: (payload, goback) => dispatch(saveTemporary(payload, goback)),
    handleDetailPayments: (payload, goback) => dispatch(detailPayments(payload, goback)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(RincianPembayaran);



