// import axios from "axios";
// import { transform } from "lodash";
import * as api from "../../utils/api.util";
import * as commonActions from "../actions/common.action";
import { 
  errorAlert, 
  // successAlert 
} from "../../utils/alert.util";

export function listInvoiceRutin(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const { page = 1 } = payload;
      const res = await api.ACADEMICS(
        { ...payload, page },
        "/payments/list-invoice-rutin"
      );
      dispatch(commonActions.loadJournalList(res));
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function listInvoiceNonRutin(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const { page = 1 } = payload;
      const res = await api.ACADEMICS(
        { ...payload, page },
        "/payments/list-invoice-nonrutin"
      );
      dispatch(commonActions.loadJournalList(res));
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function listPaymentGateway(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const { page = 1 } = payload;
      const res = await api.ACADEMICS(
        { ...payload, page },
        "/payments/list-payment-gateway"
      );
      dispatch(commonActions.loadJournalList(res));
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function saveTemporary(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));

      const res = await api.ACADEMICS(payload, "/payments/save-temp");
      return res.data || [];
    } catch (err) {
      errorAlert({
        title: "Tidak ada data yg di pilih.",
      });
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function detailPayments(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));

      const res = await api.ACADEMICS(payload, "/payments/detail-payments");

      return res || [];
    } catch (err) {
      errorAlert({
        title: "Terjadi Kesalahan",
      });
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function cancelPayments(payload) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));

      const res = await api.ACADEMICS(payload, "/payments/cancel-payments");
      return res || [];
    } catch (err) {
      errorAlert({
        title: "Terjadi Kesalahan",
      });
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function historyPaymentsRutin(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const { page = 1 } = payload;
      const res = await api.ACADEMICS(
        { ...payload, page },
        "/payments/history-rutin"
      );
      dispatch(commonActions.loadJournalList(res));
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function historyPaymentsNonRutin(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const { page = 1 } = payload;
      const res = await api.ACADEMICS(
        { ...payload, page },
        "/payments/history-non-rutin"
      );
      dispatch(commonActions.loadJournalList(res));
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function historyDetailPaymentsRutin(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const { page = 1 } = payload;
      const res = await api.ACADEMICS(
        { ...payload, page },
        "/payments/history-details-rutin"
      );
      dispatch(commonActions.loadJournalList(res));
      return {
        result: res.data,
        currentPage: res.current_page,
        totalPage: res.last_page,
        total: res.total,
      };
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function historyDetailPaymentsOffline(payload = {}) {
    return async (dispatch) => {
        try {
            dispatch(commonActions.setLoading({ show: true }));
            const { page = 1 } = payload;
            const res = await api.ACADEMICS({...payload, page}, '/payments/history-payment-offline');
            dispatch(commonActions.loadJournalList(res)); 
            return {
                result: res.data,
                currentPage: res.current_page,
                totalPage: res.last_page,
                total: res.total,
            };
        } catch (err) {
            throw err;
        } finally {
            dispatch(commonActions.setLoading({ show: false }));
        }
    };
}

export function historyDetailPaymentsH2H(payload = {}) {
    return async (dispatch) => {
        try {
            dispatch(commonActions.setLoading({ show: true }));
            const { page = 1 } = payload;
            const res = await api.ACADEMICS({...payload, page}, '/payments/history-payments-h2h');
            dispatch(commonActions.loadJournalList(res)); 
            // return {
            //     result: res.data,
            //     currentPage: res.current_page,
            //     totalPage: res.last_page,
            //     total: res.total,
            // };
            return res;
        } catch (err) {
            throw err;
        } finally {
            dispatch(commonActions.setLoading({ show: false }));
        }
    };
}

export function getHeadmaster(payload = {}) {
  return async (dispatch) => {
    try {
      // const { page = 1 } = payload;
      const res = await api.ACADEMICS({ ...payload }, "/headmaster");
      // dispatch(commonActions.loadJournalList(res));
      return res;
    } catch (err) {
      throw err;
    }
  };
}
