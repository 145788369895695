import { isEmpty, includes } from 'lodash';
import * as api from '../../utils/api.util';
import * as userActions from '../actions/user.action';
import * as commonActions from '../actions/common.action';
import {
  set, get, storageKeys, remove,
} from '../../utils/storage.util';
import { errorAlert, successAlert } from '../../utils/alert.util';

export function setupUser(history, pathname) {
  return async (dispatch) => {
    const isDashboard = includes(pathname, 'dashboard');
    const isLogin = includes(pathname, 'login');
    if (isDashboard) {
      const token = get(storageKeys.USER_TOKEN);
      if (!isEmpty(token)) {
        try {
          const user = await api.PROFILE();
          dispatch(userActions.setUser(user));
        } catch (err) {
          remove(storageKeys.USER_TOKEN);
          dispatch(commonActions.appSetup(true));
          if (!isLogin) {
            history.push('/login');
          }
        }
      } else if (!isLogin) {
        history.replace('/login');
      }
    }
    dispatch(commonActions.appSetup(true));
  };
}

export function handleLogin(payload, push) {
  return async (dispatch) => {
    try {
      const { remember_me = false } = payload;
      dispatch(commonActions.setLoading({ show: true }));
      const requestBody = new FormData();
      requestBody.append('username', payload.username);
      requestBody.append('password', payload.password);
      requestBody.append('is_ypl', 1);
      requestBody.append('remember_me', remember_me ? 1 : 0);
      const resLogin = await api.LOGIN(requestBody);
      const token = resLogin.access_token;
      document.cookie = `access_token=${token.replace(/[|]/, '')}; domain=dev.api.kliksekolah.com; path=/`;
      set(storageKeys.USER_TOKEN, token);

      const user = await api.PROFILE();
      dispatch(userActions.setUser(user));
      push('/dashboard');
    } catch (err) {
      errorAlert({
        title: err.responseMessage || 'alerts.login_failed.title',
        errorMessage: 'alerts.login_failed.message',
      });
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function handleLogout(push) {
  return (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      remove(storageKeys.USER_TOKEN);
      dispatch(userActions.resetUser());
      push('/login');
    } catch (err) {
      errorAlert({
        title: 'Logout Gagal',
      });
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function changePassword(payload, callback) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      await api.PASSWORD(payload, '/change').then(() => {
        successAlert({
          title: 'Sukses mengganti kata sandi',
          message: 'Harap login ulang menggunakan kata sandi baru anda.',
          callback,
        });
      }).catch((err) => {
        const { message } = err;
        let newMessage = '';
        if (message === 'Password does not match any records') {
          newMessage = 'Kata sandi lama salah';
        }
        errorAlert({
          title: 'Gagal Mengganti kata sandi.',
          errorMessage: newMessage,
        });
      });
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function setWorkingUnit(unit = {}) {
  return async (dispatch) => {
    dispatch(userActions.setWorkingUnit(unit));
    return unit;
  };
}
